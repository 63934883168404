import { Anchor, Popover } from '@flipgrid/flipkit';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import moderationPopoverStyles from '~/styles/components/Utility/components/ModerationPopover.css';

import type { Placement } from '@flipgrid/flipkit';

type Props = {
  id: string;
  isVisible: boolean;
  targetRef: TSFix;
  onDismiss?: () => void;
  placement?: Placement;
  useArrow?: boolean;
  type: 'Comment' | 'Description' | 'GroupName' | 'Title' | 'Caption';
};

export const links = () => [{ rel: 'stylesheet', href: moderationPopoverStyles }];

const ModerationPopover = ({
  id,
  isVisible,
  targetRef,
  onDismiss,
  type,
  placement = 'top-start',
  useArrow = true
}: Props) => {
  const moderationMessage = useMemo(() => {
    switch (type) {
      case 'Comment':
        return (
          <Trans i18nKey="shared.textModerationError">
            This comment has a blocked word. Try using different words. If we got it wrong,
            <Anchor to={externalLinks.SupportForm} data-testid="moderationPopover__anchor__supportForm" newTab>
              let us know
            </Anchor>
            .
          </Trans>);

      case 'Description':
        return (
          <Trans i18nKey="shared.descriptionModerationError">
            This description has a blocked word. Try using different words. If we got it wrong,
            <Anchor to={externalLinks.SupportForm} data-testid="moderationPopover__anchor__supportForm" newTab>
              let us know
            </Anchor>
            .
          </Trans>);

      case 'GroupName':
        return (
          <Trans i18nKey="shared.groupNameModerationError">
            This group name has a blocked word. Try using different words. If we got it wrong,
            <Anchor to={externalLinks.SupportForm} data-testid="moderationPopover__anchor__supportForm" newTab>
              let us know
            </Anchor>
            .
          </Trans>);

      case 'Title':
        return (
          <Trans i18nKey="shared.titleModerationError">
            This title has a blocked word. Try using different words. If we got it wrong,
            <Anchor to={externalLinks.SupportForm} data-testid="moderationPopover__anchor__supportForm" newTab>
              let us know
            </Anchor>
            .
          </Trans>);

      case 'Caption':
        return (
          <Trans i18nKey="shared.captionModerationError">
            This caption has a blocked word. Try using different words. If we got it wrong,
            <Anchor to={externalLinks.SupportForm} data-testid="moderationPopover__anchor__supportForm" newTab>
              let us know
            </Anchor>
            .
          </Trans>);

      default:
        return (
          <Trans i18nKey="shared.templateModerationError">
            This has a blocked word. Try using different words. If we got it wrong,
            <Anchor to={externalLinks.SupportForm} data-testid="moderationPopover__anchor__supportForm" newTab>
              let us know
            </Anchor>
            .
          </Trans>);

    }
  }, [type]);

  return (
    <Popover
      id={id}
      placement={placement}
      isVisible={isVisible}
      onDismiss={onDismiss === undefined ? () => {} : onDismiss}
      targetRef={targetRef}
      useArrow={useArrow}
      usePortal>

      <div className="moderationPopover">
        <img className="moderationPopover__emoji" src={externalLinks.RobotEmoji} alt="" />
        <span className="moderationPopover__message">{moderationMessage}</span>
      </div>
    </Popover>);

};

export default ModerationPopover;